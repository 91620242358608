.play-pause-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  outline: none;
  transition: color 0.3s;
}

input[type="range"]::-moz-range-thumb {
  height: 15px;
  width: 15px;
  background-color: #9cc500;
  border: none;
  transition: margin-left 0.1s;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 15px;
  width: 15px;
  background-color: #9cc500;
  border-radius: 50%;
  transition: margin-left 0.1s;
}

.play-pause-btn:hover {
  color: #666;
}
