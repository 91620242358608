@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom scrollbar */
div::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

div::-webkit-scrollbar-thumb {
  background: #d1d1d1;
  border-radius: 6px;
}

div::-webkit-scrollbar-thumb:hover {
  background: #b5b5b5;
}

/* dark */
html.dark div::-webkit-scrollbar-thumb {
  background: #555353;
  border-radius: 6px;
}

/* dark */
html.dark div::-webkit-scrollbar-thumb:hover {
  background: #6e6e6e;
}
